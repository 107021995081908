<template>
  <div class="content">
    <table class="table">
      <thead class="table__header">
      <tr class="table__row">
        <th class="table__header-cell">
          <div class="table__header-cell-content">Texte</div>
        </th>
      </tr>
      </thead>
      <tbody class="table__body">
      <tr class="table__row" v-for="(textObject, index) in filteredList" v-bind:key="index">
        <td class="table__cell">
          <router-link
              v-html="textObject.text"
              class="table__cell-content unformatted-text"
              v-bind:to="{
                name: 'Section',
                params: { sectionId: textObject.section_id },
                query: { section_alternative: textObject.section_alternative_id, text_alternative: textObject.text_alternative_id}
              }"
          >

          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TextOverview',
  components: {
  },
  props: {
    dataHandler: {
      type: Object,
    },
    customerId: {
      type: String,
    },
    routerData: {
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    filteredList() {
      let sectionArray = [];
      Object.entries(this.routerData).forEach( ([key, section]) => {
        console.log(key)
        section.section_alternatives.forEach( section_alternative => {
          Object.entries(section_alternative.text_alternatives).forEach( ([key1, text_alternative]) => {
            let textObject = {
              section_id: '',
              section_alternative_id: '',
              text_alternative_id: '',
              text: ''
            };
            console.log(key1);
            textObject.section_id = section.id;
            textObject.section_alternative_id = section_alternative.id;
            textObject.text_alternative_id = text_alternative.id;
            textObject.text = text_alternative.text;
            sectionArray.push(textObject)
          });
        })
      })
      return sectionArray;
    }
  },
}
</script>

<style lang="scss" scoped>

.table {
  &__body {
    max-height: 75vh;
  }

  &__cell-content {
    width: 100%;
    border-radius: 5px;
    text-decoration: none;
    display: block;
    height: rem(40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>